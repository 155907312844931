import React, { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";

import { Container, Message, AppButton } from "./PageElements";

const CatalogItemPageSection = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [os, setOS] = useState();

  const getMobileOS = () => {
    const userAgent = navigator.userAgent || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setOS("iOS");
      return;
    }

    if (/android/i.test(userAgent)) {
      setOS("Android");
      return;
    }

    setOS("unknown");
  };

  const handleClick = () => {
    if (os === "iOS") {
      alert("Link do app para iOS");
    } else if (os === "Android") {
      window.location.href = `acervo://explore/catalog/${id}`;
    } else {
      navigate("/#download");
    }
  };

  useEffect(() => {
    getMobileOS();
  }, []);

  return (
    <Container>
      <Message>
        {os === "unknown" ? (
          <AppButton onClick={handleClick}>Baixar app</AppButton>
        ) : (
          <AppButton onClick={handleClick}>Abrir link no app</AppButton>
        )}
      </Message>
    </Container>
  );
};

export default CatalogItemPageSection;
